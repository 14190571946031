import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

import logo from "../images/logo.svg"
import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="background">
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="text-center col-md-6">
          <img src={logo} alt="Logo" className="p-4"/>
          <p>We offer a premium suite of marketing, design and consulting services to a diverse group of political, corporate and non-profit clients.</p>
          <p className="font-weight-bold font-italic">What are you waiting for? <a href="mailto:hello@movestrategic.com">Let’s get moving ›</a></p>
          <a href="https://facebook.com/MoveStrategic" aria-label="Move Strategic on Facebook" target="_blank" rel="nofollow noopener noreferrer"><FaFacebook className="mx-3 text-muted" style={{fontSize: "1.25rem"}} /></a>
          <a href="https://twitter.com/MoveStrategic" aria-label="Move Strategic on Twitter" target="_blank" rel="nofollow noopener noreferrer"><FaTwitter className="mx-3 text-muted" style={{fontSize: "1.25rem"}} /></a>
          <a href="https://linkedin.com/company/MoveStrategic" aria-label="Move Strategic on LinkedIn" target="_blank" rel="nofollow noopener noreferrer"><FaLinkedin className="mx-3 text-muted" style={{fontSize: "1.25rem"}} /></a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
